import React from 'react'
import intl from 'react-intl-universal';
import { Container, Row, Col } from 'reactstrap';
import IconCdbaby from './icons/cdbaby';
import { FaItunes, FaGooglePlay, FaAmazon } from 'react-icons/fa';
import { IconContext } from "react-icons";

const Music = (props) => (
  <IconContext.Provider value={{ color: "black", className: "music-icons" }}>
    <div className="page page-regular page-music">
      <Container>
        <Row>
          <Col>
            <h2>{intl.get('pageMusic')}</h2>
          </Col>
        </Row>
        <Row>
          <Col>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Beaton-Plasse</h5>
              <Container>
                <Row>
                  <Col>
                    <div className="px-3">
                      <ol>
                        <li>Glasgow Session / Les quatre violoneuses</li>
                        <li>Jackie and Jill / Le rapide de l'orignal</li>
                        <li>Charles MacCuspic / Hommage à Jo Bouchard / La tondeuse</li>
                        <li>Le brise-glace</li>
                        <li>Grand Étang / The Haughs of Cromdale / Mick's Knitted Triplets / Reel en sol</li>
                        <li>Le musicien</li>
                        <li>Burning Man / Clog à Ti-Jules</li>
                        <li>L'invitation au voyage</li>
                        <li>La valse à Peter</li>
                        <li>The McKinnons of PEI / Isle Inspired / A Gubleration for Gubler Nation</li>
                        <li>Cairo Waltz / The Elegant Gentleman / Reel Picard / Ôte tes raquettes</li>
                      </ol>
                    </div>
                    <div>
                      <p>
                        <strong>{intl.get('musicDownload')}</strong><br />
                        <ul className="music-download-list">
                          <li>
                            <a className="download-link" href="https://store.cdbaby.com/cd/beatonplasse">
                              <IconCdbaby className="cdbaby-icon" />cdbaby
                            </a>
                          </li>
                          <li>
                            <a className="download-link" href="https://geo.itunes.apple.com/ca/album/beaton-plasse/1438686017">
                              <FaItunes />itunes
                            </a>
                          </li>
                          <li>
                            <a className="download-link" href="https://play.google.com/store/music/album/Beaton_Plasse_Beaton_Plasse?id=Bka4nvmfrcsocvucy4y4xazyvmy">
                              <FaGooglePlay />Google Play music
                            </a>
                          </li>
                          <li>
                            <a className="download-link" href="https://www.amazon.com/gp/product/B07HVMVX12/ref=dm_ws_sp_ps_dp">
                              <FaAmazon />amazon music
                            </a>
                          </li>
                        </ul>
                      </p>
                      <p>
                        <strong>{intl.get('musicBuyPhysical')}</strong><br />
                        <ul className="music-buy-list">
                          <li>
                            <div>
                              <strong>{intl.get('musicCanadaShipping')}</strong><br />
                              {intl.get('musicBuyPhysicalDetails', { price: 20, shipping: 3 })}
                              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                                <input type="hidden" name="cmd" value="_s-xclick" />
                                <input type="hidden" name="hosted_button_id" value="U64UFCGWP8UYL" />
                                <input type="image" src={intl.get('musicBuyButtonImage')} border="0" name="submit" alt={intl.get('musicBuyButtonAlternativeText')} />
                                <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                              </form>
                            </div>
                          </li>
                          <li>
                            <div>
                              <strong>{intl.get('musicUnitedStatesShipping')}</strong><br />
                              {intl.get('musicBuyPhysicalDetails', { price: 20, shipping: 5 })}
                              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                                <input type="hidden" name="cmd" value="_s-xclick" />
                                <input type="hidden" name="hosted_button_id" value="97MKPAQGSYGEC" />
                                <input type="image" src={intl.get('musicBuyButtonImage')} border="0" name="submit" alt={intl.get('musicBuyButtonAlternativeText')} />
                                <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                              </form>
                            </div>
                          </li>
                          <li>
                            <div>
                              <strong>{intl.get('musicInternationalShipping')}</strong><br />
                              {intl.get('musicBuyPhysicalDetails', { price: 20, shipping: 10 })}
                              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                                <input type="hidden" name="cmd" value="_s-xclick" />
                                <input type="hidden" name="hosted_button_id" value="RVNALFMB54DY4" />
                                <input type="image" src={intl.get('musicBuyButtonImage')} border="0" name="submit" alt={intl.get('musicBuyButtonAlternativeText')} />
                                <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                              </form>
                            </div>
                          </li>
                        </ul>
                      </p>
                    </div>
                  </Col>
                  <div className="w-100 d-md-none d-block"></div>
                  <Col>
                    <img
                      src="/images/press/low-res/beaton-plasse-1.jpg"
                      alt={intl.get('pressKitCoverText')}
                      className="img-thumbnail float-right w-100"
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          </Col>
        </Row>
      </Container>
    </div>
  </IconContext.Provider>
)

export default Music;
